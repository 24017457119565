import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './styles/personal.css';
import './styles/transactions.css';
import { FaArrowLeft, FaUser, FaEnvelope, FaPhone, FaCalendar, FaDollarSign, FaLink, FaCheckCircle } from 'react-icons/fa';
import { BsClipboard2Check } from 'react-icons/bs';
import HashLoader from 'react-spinners/HashLoader';

const Personal = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;
  const [message, setMessage] = useState('');
  const [loadingPrioritize, setLoadingPrioritize] = useState(false);
  const [loadingDeactivate, setLoadingDeactivate] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://api.visaro.ng/api/v2/admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setUser(data.data[0]);
          setTransactions(data.data[0].transactions || []);
        } else {
          throw new Error(data.message || 'Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, [userId]);

  const handlePrioritize = async () => {
    setLoadingPrioritize(true);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.visaro.ng/api/v2/admin/users/${userId}/prioritize`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status) {
        setMessage('User prioritized successfully!');
        setTimeout(() => setMessage(''), 3000);
      } else {
        throw new Error(data.message || 'Failed to prioritize user');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setLoadingPrioritize(false);
    }
  };

  const handleDeactivate = async () => {
    setLoadingDeactivate(true);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.visaro.ng/api/v2/admin/users/${userId}/deactivate`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status) {
        setMessage('User deactivated successfully!');
        setTimeout(() => setMessage(''), 3000);
      } else {
        throw new Error(data.message || 'Failed to deactivate user');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setLoadingDeactivate(false);
    }
  };

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(transactions.length / transactionsPerPage);

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  return (
    <div className="personal-page">
      <div className="breadcrumb">
        <FaArrowLeft onClick={() => navigate(-1)} className="back-arrow" />
        <span className="go-back">Go back</span>
        <span className="nav-path">Dashboard / User / {user.username || user.email}</span>
      </div>

      <div className="user-info">
        <div className="user-data">
          <img src={user.photo} alt="Profile" className="profile-pic-large" />
          <div>
            <h3>{user.username || 'No Username'}</h3>
            <p>{user.email}</p>
          </div>
        </div>
        <div className="action-buttons">
          <button className="prioritize-button" onClick={handlePrioritize}>
            {loadingPrioritize ? <HashLoader color="#ffffff" size={24} /> : 'Prioritize User'}
          </button>
          <button className="deactivate-button" onClick={handleDeactivate}>
            {loadingDeactivate ? <HashLoader color="#ffffff" size={24} /> : 'Deactivate User Account'}
          </button>
        </div>
      </div>

      {message && <div className="message">{message}</div>}

      <div className="tabs">
        <div className={`tab ${activeTab === 'overview' ? 'active' : ''}`} onClick={() => setActiveTab('overview')}>Overview</div>
        <div className={`tab ${activeTab === 'transactions' ? 'active' : ''}`} onClick={() => setActiveTab('transactions')}>Transactions</div>
      </div>

      {activeTab === 'overview' && (
        <div className="overview-section">
          <div className="info-column">
            <div className="info-item">
              <div className='info-data'>
                <FaUser className="info-icon" />
                <label>User ID:</label>
                <br />
                <span>{user.public_id}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.public_id)} />
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaEnvelope className="info-icon" />
                <label>Email:</label>
                <br />
                <span>{user.email}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.email)} />
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaUser className="info-icon" />
                <label>First Name:</label>
                <br />
                <span>{user.firstname || 'No First Name'}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.firstname)} />
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaUser className="info-icon" />
                <label>Last Name:</label>
                <br />
                <span>{user.lastname || 'No Last Name'}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.lastname)} />
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaPhone className="info-icon" />
                <label>Phone Number:</label>
                <br />
                <span>{user.phone || 'No Phone Number'}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.phone)} />
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaCheckCircle className="info-icon" />
                <label>Status:</label>
                <br />
                <span>{user.status}</span>
              </div>
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaCalendar className="info-icon" />
                <label>Onboarding Date:</label>
                <br />
                <span>{new Date(user.created_at).toLocaleDateString()}</span>
              </div>
            </div>
          </div>

          <div className="info-column">
            <div className="info-item">
              <div className='info-data'>
                <FaDollarSign className="info-icon" />
                <label>Total Funds Requested:</label>
                <br />
                <span>{user.totalFundsRequested || 'N/A'}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.totalFundsRequested)} />
            </div>
            <div className="info-item">
              <div className='info-data'>
                <FaLink className="info-icon" />
                <label>Code:</label>
                <br />
                <span>{user.code}</span>
              </div>
              <BsClipboard2Check className="copy-icon" onClick={() => handleCopy(user.qrcode)} />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'transactions' && (
        <div className="transactions-section">
          {currentTransactions.length > 0 ? (
            <>
              <table className="transactions-table">
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions.map((transaction) => (
                    <tr key={transaction.transaction_id}>
                      <td>{transaction.transaction_id}</td>
                      <td>{transaction.amount}</td>
                      <td>{new Date(transaction.created_at).toLocaleDateString()}</td>
                      <td>{transaction.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                {[...Array(totalPages).keys()].map((pageNumber) => (
                  <button
                    key={pageNumber + 1}
                    className={currentPage === pageNumber + 1 ? 'active' : ''}
                    onClick={() => paginate(pageNumber + 1)}
                  >
                    {pageNumber + 1}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <p>No transactions found for this user.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Personal;
