import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight, FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './styles/marchant.css';

const Marchant = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [marchants, setMarchants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarchants = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(`https://api.visaro.ng/api/v2/admin/merchants`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (response.ok) {
          setMarchants(data.data.data); 
        } else {
          throw new Error(data.message || 'Failed to fetch merchants data');
        }
      } catch (error) {
        console.error('Error fetching merchants data:', error);
      }
    };

    fetchMarchants();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const handleDropdown = (index) => {
    if (dropdownVisible && dropdownIndex === index) {
      setDropdownVisible(false);
      setDropdownIndex(null);
    } else {
      setDropdownVisible(true);
      setDropdownIndex(index);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/marchant/${id}`);
  };

  const handleCreateMarchant = () => {
    navigate(`/create-marchant`);
  };

  const filteredMarchants = marchants.filter(
    (marchant) =>
      marchant.business_name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      marchant.public_id.toLowerCase().includes(filter.toLowerCase())
  );

  const marchantsPerPage = 5;
  const totalPages = Math.ceil(filteredMarchants.length / marchantsPerPage);
  const startIdx = (currentPage - 1) * marchantsPerPage;
  const currentMarchants = filteredMarchants.slice(startIdx, startIdx + marchantsPerPage);

  return (
    <div className="marchants-page">
      <div className="marchant-table-section">
        <div className="marchant-table-controls">
          <input
            type="text"
            placeholder="Search merchants"
            value={searchTerm}
            onChange={handleSearch}
            className="search-bar"
          />
          <input
            type="text"
            placeholder="Filter by ID, Name"
            value={filter}
            onChange={handleFilter}
            className="filter-bar"
          />
          <button className="add-marchant-btn" onClick={handleCreateMarchant}>+ Register Merchant</button>
        </div>
        
        <table className="marchant-table">
          <thead>
            <tr>
              <th>
                <div className="select-all">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th>Name</th>
              <th>Merchant Code</th>
              <th>Email</th>
              <th>Category</th>
              <th>Date of Onboarding</th>
              <th>Total Funds Requested</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentMarchants.map((marchant, index) => (
              <tr key={marchant.id} onClick={() => handleRowClick(marchant.id)}>
                <td>
                  <input type="checkbox" checked={selectAll} />
                </td>
                <td className='name-click'>
                  <img src={marchant.profilePic} alt="Profile" className="profile-pic" />
                  {marchant.business_name}
                </td>
                <td>{marchant.code}</td>
                <td>{marchant.business_email}</td>
                <td>{marchant.category}</td>
                <td>{new Date(marchant.created_at).toLocaleDateString()}</td>
                <td>{marchant.totalFundsRequested}</td>
                <td className="dropdown-cell">
                  <FaEllipsisV
                    className="dropdown-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDropdown(index);
                    }}
                  />
                  {dropdownVisible && dropdownIndex === index && (
                    <div className="dropdown-menu">
                      <div className="dropdown-item">Edit</div>
                      <div className="dropdown-item">Delete</div>
                      <div className="dropdown-item">Manage</div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            <FaArrowLeft />
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Marchant;
