import React, { useState } from 'react';
import HashLoader from 'react-spinners/HashLoader';
import Modal from 'react-modal';
import './styles/notification.css';

const Notification = () => {
  const [formData, setFormData] = useState({
    recipients: '',
    subject: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveDraft = () => {
    console.log('Draft saved:', formData);
    alert('Draft saved successfully');
  };

  const handleSendNow = async () => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://api.visaro.ng/api/v2/admin/send-notification', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: new URLSearchParams({
          recipients: formData.recipients,
          subject: formData.subject,
          message: formData.message,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status) {
        setIsModalOpen(true);
      } else {
        setError(result.message || 'Failed to send notification');
      }
    } catch (err) {
      setError('Error sending notification: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="notification-container">
      <h4 className="title">Send Notification</h4>
      <form className="notification-form" onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="recipients">Recipients:</label>
          <input
            type="text"
            id="recipients"
            name="recipients"
            value={formData.recipients}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        {loading && (
          <div className="loader">
            <HashLoader size={50} color={"#36D7B7"} loading={loading} />
          </div>
        )}
        {error && <div className="error">{error}</div>}
        <div className="button-group">
          <button type="button" className="save-draft-button" onClick={handleSaveDraft}>
            Save Draft
          </button>
          <button
            type="button"
            className="send-now-button"
            onClick={handleSendNow}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Now'}
          </button>
        </div>
      </form>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Success Modal"
        className="success-modal"
        overlayClassName="modal-overlay"
      >
        <h2>Notification Sent</h2>
        <p>Your notification has been sent successfully!</p>
        <button onClick={() => setIsModalOpen(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default Notification;
