import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Marchant from './pages/Marchant';
import './App.css';
import Login from './pages/Login';
import Report from './pages/Report';
import Support from './pages/Support';
import Notification from './pages/Notification';
import AddAdmin from './pages/AddAdmin';
import Personal from './pages/Personal';
import PersonalMarchant from './pages/PersonalMarchant';
import CreateMarchant from './pages/CreateMarchant';
import DisbursePartner from './pages/DisbursePartner';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="App">
        {isAuthenticated && <Sidebar />}
        <div className="main-content">
          {isAuthenticated && <Navbar />}
          <div className="content">
            <Routes>
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
              <Route path="/users" element={<PrivateRoute element={<Users />} />} />
              <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
              <Route path="/marchants" element={<PrivateRoute element={<Marchant />} />} />
              <Route path="/report" element={<PrivateRoute element={<Report />} />} />
              <Route path="/support" element={<PrivateRoute element={<Support />} />} />
              <Route path="/notifications" element={<PrivateRoute element={<Notification />} />} />
              <Route path="/addadmin" element={<PrivateRoute element={<AddAdmin />} />} />
              <Route path="/personal/:userId" element={<PrivateRoute element={<Personal />} />} />
              <Route path="/marchant/:id" element={<PrivateRoute element={<PersonalMarchant />} />} />
              <Route path="/create-marchant" element={<PrivateRoute element={<CreateMarchant />} />} />
              <Route path="/disburse-partners" element={<PrivateRoute element={<DisbursePartner />} />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
