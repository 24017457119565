import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { HashLoader } from 'react-spinners';
import './styles/settings.css';

const Settings = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdmins = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://api.visaro.ng/api/v2/admin/get-all-admin', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setAdmins(data.data);
        }
      } catch (error) {
        console.error('Error fetching admins:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdmins();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleFilter = (e) => setFilter(e.target.value);
  const handleSelectAll = () => setSelectAll(!selectAll);
  const handleCreateAdmin = () => navigate('/addadmin');

  const filteredAdmins = admins.filter(
    (admin) =>
      admin.username.toLowerCase().includes(searchTerm.toLowerCase()) &&
      admin.public_id.toLowerCase().includes(filter.toLowerCase())
  );

  const adminsPerPage = 5;
  const totalPages = Math.ceil(filteredAdmins.length / adminsPerPage);
  const startIdx = (currentPage - 1) * adminsPerPage;
  const currentAdmins = filteredAdmins.slice(startIdx, startIdx + adminsPerPage);

  const exportToCSV = () => {
    const headers = ['Name', 'ID', 'Email', 'Role', 'Date of Onboarding'];
    const rows = admins.map(admin => [
      admin.username,
      admin.public_id,
      admin.email,
      admin.role,
      new Date(admin.created_at).toLocaleDateString()
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'admin_users.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div className="loader-overlay">
        <HashLoader size={50} color="#000" />
      </div>
    );
  }

  return (
    <div className="settings-page">
      <div className="user-table-section">
        <div className="user-table-controls">
          <input
            type="text"
            placeholder="Search admins"
            value={searchTerm}
            onChange={handleSearch}
            className="search-bar"
          />
          <input
            type="text"
            placeholder="Filter by ID, Name"
            value={filter}
            onChange={handleFilter}
            className="filter-bar"
          />
          <button className="csv" onClick={exportToCSV}>Export CSV</button>
          <button className="add-admin-btn" onClick={handleCreateAdmin}>
            + Create admin user
          </button>
        </div>
        <table className="user-table">
          <thead>
            <tr>
              <th>
                <div className="select-all">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th>Name</th>
              <th>ID</th>
              <th>Email</th>
              <th>Role</th>
              <th>Date of Onboarding</th>
            </tr>
          </thead>
          <tbody>
            {currentAdmins.map((admin) => (
              <tr key={admin.id}>
                <td>
                  <input type="checkbox" checked={selectAll} />
                </td>
                <td>{admin.username}</td>
                <td>{admin.public_id}</td>
                <td>{admin.email}</td>
                <td>{admin.role}</td>
                <td>{new Date(admin.created_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            <FaArrowLeft />
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
