import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./styles/report.css";
import HashLoader from "react-spinners/HashLoader";
import { FaHandHoldingUsd, FaCalendarAlt, FaDownload } from "react-icons/fa";

const Report = () => {
  const [revenueData, setRevenueData] = useState(null);
  const [expensesData, setExpensesData] = useState(null);
  const [profitData, setProfitData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const [revenueResponse, expensesResponse, profitResponse] =
        await Promise.all([
          axios.get(`https://api.visaro.ng/api/v2/admin/reports/revenue`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { start_date: startDate, end_date: endDate },
          }),
          axios.get(`https://api.visaro.ng/api/v2/admin/reports/expenses`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { start_date: startDate, end_date: endDate },
          }),
          axios.get(`https://api.visaro.ng/api/v2/admin/reports/profit`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { start_date: startDate, end_date: endDate },
          }),
        ]);

      setRevenueData(revenueResponse.data);
      setExpensesData(expensesResponse.data);
      setProfitData(profitResponse.data);
    } catch (error) {
      setErrorMessage("Error fetching data.");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate, fetchData]);

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]);
    const csvRows = [
      header.join(","), // Header row
      ...data.map((row) =>
        header
          .map((fieldName) =>
            JSON.stringify(row[fieldName], (key, value) =>
              value === null ? "" : value
            )
          )
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  };

  const downloadCSV = (data, type) => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${type}-report.csv`);
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleExportCSV = (type) => {
    let data;

    if (type === "revenue" && Array.isArray(revenueData?.items)) {
      data = revenueData.items;
    } else if (type === "expenses" && Array.isArray(expensesData?.items)) {
      data = expensesData.items;
    } else if (type === "profit" && Array.isArray(profitData?.items)) {
      data = profitData.items;
    } else {
      setErrorMessage("No data available");
      return;
    }

    setErrorMessage(""); // Clear any previous error message
    downloadCSV(data, type);
  };

  return (
    <div className="Container">
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="Section">
        <h4 className="title">Revenue Tracking</h4>
        <div className="filter-container">
          <div className="date-filter">
            <div className="date-box">
              <FaCalendarAlt className="calendar-icon" />
              <input
                type="date"
                className="dateinput"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-to">
              <p className="to">to</p>
            </div>
            <div className="date-box">
              <FaCalendarAlt className="calendar-icon" />
              <input
                type="date"
                className="dateinput"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <button
            className="export-button"
            onClick={() => handleExportCSV("revenue")}
          >
            <FaDownload className="download-icon" /> Export CSV
          </button>
        </div>
        {loading ? (
          <div className="loading-spinner">
            <HashLoader color="#333" size={50} />
          </div>
        ) : (
          <div className="stats-container">
            <div className="stat-box">
              <FaHandHoldingUsd className="stat-icon1" />
              <h5>Total Revenue Generated</h5>
              <div className="staticon">
                <p className="amount">N{revenueData?.total || "0"}</p>
                <p className="statis">
                  {revenueData?.change || "+0%"} since last month
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="Section">
        <h4 className="title">Expense Tracking</h4>
        <div className="filter-container">
          <div className="date-filter">
            <div className="date-box">
              <FaCalendarAlt className="calendar-icon" />
              <input
                type="date"
                className="dateinput"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-to">
              <p className="to">to</p>
            </div>
            <div className="date-box">
              <FaCalendarAlt className="calendar-icon" />
              <input
                type="date"
                className="dateinput"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <button
            className="export-button"
            onClick={() => handleExportCSV("expenses")}
          >
            <FaDownload className="download-icon" /> Export CSV
          </button>
        </div>
        {loading ? (
          <div className="loading-spinner">
            <HashLoader color="#333" size={50} />
          </div>
        ) : (
          <div className="stats-container">
            <div className="stat-box">
              <FaHandHoldingUsd className="stat-icon1" />
              <h5>Total Expenses</h5>
              <div className="staticon">
                <p className="amount">N{expensesData?.total || "0"}</p>
                <p className="statis">
                  {expensesData?.change || "+0%"} since last month
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="Section">
        <h4 className="title">Profit Tracking</h4>
        <div className="filter-container">
          <div className="date-filter">
            <div className="date-box">
              <FaCalendarAlt className="calendar-icon" />
              <input
                type="date"
                className="dateinput"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-to">
              <p className="to">to</p>
            </div>
            <div className="date-box">
              <FaCalendarAlt className="calendar-icon" />
              <input
                type="date"
                className="dateinput"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <button
            className="export-button"
            onClick={() => handleExportCSV("profit")}
          >
            <FaDownload className="download-icon" /> Export CSV
          </button>
        </div>
        {loading ? (
          <div className="loading-spinner">
            <HashLoader color="#333" size={50} />
          </div>
        ) : (
          <div className="stats-container">
            <div className="stat-box">
              <FaHandHoldingUsd className="stat-icon1" />
              <h5>Total Profit</h5>
              <div className="staticon">
                <p className="amount">N{profitData?.total || "0"}</p>
                <p className="statis">
                  {profitData?.change || "+0%"} since last month
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Report;
