import React, { useEffect, useState } from 'react';
import { FaUsers, FaStore, FaHandHoldingUsd } from 'react-icons/fa';
import './styles/dashboard.css';
import { HashLoader } from 'react-spinners';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      const token = localStorage.getItem('token');
      if (!token) {
        setError('Authentication token not found.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://api.visaro.ng/api/v2/admin/dashboard', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const result = await response.json();

        if (response.ok && result.status) {
          setData(result.data);
        } else {
          setError(result.message || 'Failed to fetch dashboard data.');
        }
      } catch (error) {
        setError('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <HashLoader size={50} color="#000" />
      </div>
    );
  }

  if (error) {
    return <div className="error-text">{error}</div>;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-section">
        <h4 className="dashboard-title">People</h4>
        <div className="dashboard-stats-container">
          <div className="dashboard-stat-box">
            <h5>Total Onboard Users</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.OnboardedUser}</p>
              <FaUsers className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Total Onboard Merchants</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.OnboardedUserMerchants}</p>
              <FaStore className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Total Disbursement Partners</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.DisbursementPartners}</p>
              <FaHandHoldingUsd className="dashboard-stat-icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-section">
        <h4 className="dashboard-title">Activity</h4>
        <div className="dashboard-stats-container">
          <div className="dashboard-stat-box">
            <h5>Active Users</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.ActiveUsers}</p>
              <FaUsers className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Active Merchants</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.ActiveMerchants}</p>
              <FaStore className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Active Disbursement Partners</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.TotalActiveDisbursementPartners}</p>
              <FaHandHoldingUsd className="dashboard-stat-icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-section">
        <h4 className="dashboard-title">Financial</h4>
        <div className="dashboard-stats-container">
          <div className="dashboard-stat-box">
            <h5>Total Revenue Generated</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.TotalRevenueGenerated}</p>
              <FaUsers className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Unpaid Loans</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.UnpaidLoans}</p>
              <FaStore className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Total Industry Interest</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.TotalIndustryInterest}</p>
              <FaHandHoldingUsd className="dashboard-stat-icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-section">
        <h4 className="dashboard-title">Funds</h4>
        <div className="dashboard-stats-container">
          <div className="dashboard-stat-box">
            <h5>Total Fund Requests</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.TotalFundRequests}</p>
              <FaUsers className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Total Approved Requests</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.TotalApprovedRequest}</p>
              <FaStore className="dashboard-stat-icon" />
            </div>
          </div>
          <div className="dashboard-stat-box">
            <h5>Total Declined Requests</h5>
            <div className="dashboard-stat-content">
              <p className="dashboard-stat-amount">{data.TotalDeclinedRequest}</p>
              <FaHandHoldingUsd className="dashboard-stat-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
