import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FaArrowLeft,
  FaUser,
  FaEnvelope,
  FaCalendar,
  FaBuilding,
  FaPhone,
  FaIdCard,
  FaGlobe,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { BsClipboard2Check } from "react-icons/bs";
import { HashLoader } from "react-spinners";
import "./styles/personal.css";
import "./styles/transactions.css";

const PersonalMerchant = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("overview");
  const [merchant, setMerchant] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [deactivating, setDeactivating] = useState(false);
  const [activating, setActivating] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const transactionsPerPage = 10;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchMerchant = async () => {
      try {
        const response = await axios.get(
          `https://api.visaro.ng/api/v2/admin/merchants/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMerchant(response.data.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch merchant data.");
        setLoading(false);
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          `https://api.visaro.ng/api/v2/admin/merchants/${id}/history`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransactions(response.data.data || []);
      } catch (error) {
        setError("Failed to fetch transactions.");
      }
    };

    fetchMerchant();
    fetchTransactions();
  }, [id, token]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setStatusMessage("Copied to clipboard!");
    setTimeout(() => setStatusMessage(""), 3000);
  };

  const handleDeactivate = async () => {
    setDeactivating(true);
    try {
      await axios.post(
        `https://api.visaro.ng/api/v2/admin/merchants/${id}/deactivate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatusMessage("Merchant account deactivated successfully");
    } catch (error) {
      setStatusMessage("Failed to deactivate merchant account");
    } finally {
      setDeactivating(false);
      setTimeout(() => setStatusMessage(""), 3000);
    }
  };

  const handleActivate = async () => {
    setActivating(true);
    try {
      await axios.post(
        `https://api.visaro.ng/api/v2/admin/merchants/${id}/activate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatusMessage("Merchant account activated successfully");
    } catch (error) {
      setStatusMessage("Failed to activate merchant account");
    } finally {
      setActivating(false);
      setTimeout(() => setStatusMessage(""), 3000);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!merchant) {
    return <div>Merchant not found</div>;
  }

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(transactions.length / transactionsPerPage);

  return (
    <div className="personal-page">
      <div className="breadcrumb">
        <FaArrowLeft onClick={() => navigate(-1)} className="back-arrow" />
        <span className="go-back">Go back</span>
        <span className="nav-path">
          Dashboard / Merchant / {merchant.business_name}
        </span>
      </div>

      <div className="user-info">
        <div className="user-data">
          <img
            src={merchant.logo || "/path/to/default-logo.png"}
            alt="Profile"
            className="profile-pic-large"
          />
          <div>
            <h3>{merchant.business_name}</h3>
            <p>{merchant.business_email}</p>
          </div>
        </div>
        <div className="action-buttons">
          <button
            className="activate-button"
            onClick={handleActivate}
            disabled={activating}
          >
            {activating ? (
              <HashLoader size={20} color="#fff" />
            ) : (
              "Activate Merchant"
            )}
          </button>
          <button
            className="theactivate-button"
            onClick={handleDeactivate}
            disabled={deactivating}
          >
            {deactivating ? (
              <HashLoader size={20} color="#fff" />
            ) : (
              "Deactivate Merchant Account"
            )}
          </button>
        </div>
      </div>

      {statusMessage && <div className="status-message">{statusMessage}</div>}

      <div className="tabs">
        <div
          className={`tab ${activeTab === "overview" ? "active" : ""}`}
          onClick={() => setActiveTab("overview")}
        >
          Overview
        </div>
        <div
          className={`tab ${activeTab === "history" ? "active" : ""}`}
          onClick={() => setActiveTab("history")}
        >
          History
        </div>
      </div>

      {activeTab === "overview" && (
        <div className="overview-section">
          <div className="info-column">
            <div className="info-item">
              <div className="info-data">
                <FaUser className="info-icon" />
                <label>Merchant ID:</label>
                <br />
                <span>{merchant.id}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() => handleCopy(merchant.id)}
              />
            </div>
            <div className="info-item">
              <div className="info-data">
                <FaEnvelope className="info-icon" />
                <label>Email:</label>
                <br />
                <span>{merchant.business_email}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() => handleCopy(merchant.business_email)}
              />
            </div>
            <div className="info-item">
              <div className="info-data">
                <FaCalendar className="info-icon" />
                <label>Onboarding Date:</label>
                <br />
                <span>
                  {new Date(merchant.created_at).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>

          <div className="info-column">
            <div className="info-item">
              <div className="info-data">
                <FaBuilding className="info-icon" />
                <label>Business Name:</label>
                <br />
                <span>{merchant.business_name}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() => handleCopy(merchant.business_name)}
              />
            </div>

            <div className="info-item">
              <div className="info-data">
                <FaUser className="info-icon" />
                <label>Business Type:</label>
                <br />
                <span>{merchant.business_type}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() => handleCopy(merchant.business_type)}
              />
            </div>

            <div className="info-item">
              <div className="info-data">
                <FaPhone className="info-icon" />
                <label>Phone:</label>
                <br />
                <span>{`${merchant.business_phone_code} ${merchant.business_phone}`}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() =>
                  handleCopy(
                    `${merchant.business_phone_code} ${merchant.business_phone}`
                  )
                }
              />
            </div>

            <div className="info-item">
              <div className="info-data">
                <FaIdCard className="info-icon" />
                <label>Registration Number:</label>
                <br />
                <span>{merchant.business_registration_number}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() =>
                  handleCopy(merchant.business_registration_number)
                }
              />
            </div>

            <div className="info-item">
              <div className="info-data">
                <FaGlobe className="info-icon" />
                <label>Country:</label>
                <br />
                <span>{merchant.country_id}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() => handleCopy(merchant.country_id)}
              />
            </div>

            <div className="info-item">
              <div className="info-data">
                <FaMapMarkerAlt className="info-icon" />
                <label>State:</label>
                <br />
                <span>{merchant.state_id}</span>
              </div>
              <BsClipboard2Check
                className="copy-icon"
                onClick={() => handleCopy(merchant.state_id)}
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === "history" && (
        <div className="history-section">
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{transaction.id}</td>
                  <td>{new Date(transaction.date).toLocaleDateString()}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalMerchant;
