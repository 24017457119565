import React, { useState, useEffect } from 'react';
import { FaUsers, FaStore, FaHandHoldingUsd, FaArrowLeft, FaArrowRight, FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { HashLoader } from 'react-spinners';
import './styles/user.css';

const Users = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [verifiedUsers, setVerifiedUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [dailyUsers, setDailyUsers] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://api.visaro.ng/api/v2/admin/users', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setUsers(data.data.users.data);
          setVerifiedUsers(data.data.VerifiedUsers);
          setActiveUsers(data.data.ActiveUsers);
          setDailyUsers(data.data.DailyUsers);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleFilter = (e) => setFilter(e.target.value);
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedUsers(currentUsers.map(user => user.id));
    } else {
      setSelectedUsers([]);
    }
  };
  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };
  const handleDropdown = (index) => {
    if (dropdownVisible && dropdownIndex === index) {
      setDropdownVisible(false);
      setDropdownIndex(null);
    } else {
      setDropdownVisible(true);
      setDropdownIndex(index);
    }
  };
  const handleViewProfile = (userId) => navigate(`/personal/${userId}`);

  const filteredUsers = users.filter(
    (user) => {
      const username = user.firstname || '';
      const publicId = user.public_id || '';
      return (
        username.toLowerCase().includes(searchTerm.toLowerCase()) &&
        publicId.toLowerCase().includes(filter.toLowerCase())
      );
    }
  );

  const usersPerPage = 10;
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const startIdx = (currentPage - 1) * usersPerPage;
  const currentUsers = filteredUsers.slice(startIdx, startIdx + usersPerPage);

  if (loading) {
    return (
      <div className="loader-container">
        <HashLoader size={50} color="#000" />
      </div>
    );
  }

  return (
    <div className="users-container">
      <div className="section-header">
        <h4 className="section-title">All Users</h4>
        <div className="stats-container">
          <div className="stat-card">
            <h5 className="stat-title">Verified users (KYC)</h5>
            <div className="stat-content">
              <p className="stat-value">{verifiedUsers}</p>
              <FaUsers className="stat-icon" />
            </div>
            <p className="stat-change">+5% since last month</p>
          </div>
          <div className="stat-card">
            <h5 className="stat-title">Active users</h5>
            <div className="stat-content">
              <p className="stat-value">{activeUsers}</p>
              <FaStore className="stat-icon" />
            </div>
            <p className="stat-change">+5% since last month</p>
          </div>
          <div className="stat-card">
            <h5 className="stat-title">Daily users</h5>
            <div className="stat-content">
              <p className="stat-value">{dailyUsers}</p>
              <FaHandHoldingUsd className="stat-icon" />
            </div>
            <p className="stat-change">+5% since last month</p>
          </div>
        </div>
      </div>

      <div className="table-section">
        <div className="table-controls">
          <input
            type="text"
            placeholder="Search users"
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <input
            type="text"
            placeholder="Filter by ID, Name"
            value={filter}
            onChange={handleFilter}
            className="filter-input"
          />
        </div>
        <table className="user-table">
          <thead>
            <tr>
              <th>
                <div className="select-all-wrapper">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th>Name</th>
              <th>Code</th>
              <th>Email</th>
              <th>Date of Onboarding</th>
              <th>Total Funds Requested</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, index) => (
              <tr key={user.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleSelectUser(user.id)}
                  />
                </td>
                <td onClick={() => handleViewProfile(user.id)} className="user-name">
                  <img src={user.photo} alt="Profile" className="profile-image" />
                  {user.firstname} {user.lastname}
                </td>
                <td>{user.code}</td>
                <td>{user.email}</td>
                <td>{user.compliance ? new Date(user.compliance.created_at).toLocaleDateString() : 'N/A'}</td>
                <td>{user.totalFundsRequested || '$0'}</td>
                <td>{user.status}</td>
                <td className="dropdown-wrapper">
                  <FaEllipsisV
                    className="dropdown-toggle"
                    onClick={() => handleDropdown(index)}
                  />
                  {dropdownVisible && dropdownIndex === index && (
                    <div className="dropdown-menu">
                      <div className="dropdown-item">Edit</div>
                      <div className="dropdown-item">Delete</div>
                      <div className="dropdown-item">Manage</div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-controls">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            <FaArrowLeft />
          </button>
          <span className="pagination-info">Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Users;
