import React, { useState } from 'react';
import axios from 'axios';
import HashLoader from 'react-spinners/HashLoader'; 
import './styles/createMarchant.css';
import { useNavigate } from 'react-router-dom';

const CreateMarchant = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    business_name: '',
    business_type: 'corporation',
    business_email: '',
    business_phone_code: '+234',
    business_phone: '',
    business_registration_number: '',
    acronym: '',
    official_name: '',
    website: '',
    presence: 'physical',
    country_id: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveDraft = () => {
    localStorage.setItem('marchantDraft', JSON.stringify(formData));
    alert('Draft saved!');
  };

  const handleCreateMarchant = async () => {
    const token = localStorage.getItem('token');
    setLoading(true); 

    try {
      await axios.post('https://api.visaro.ng/api/v2/admin/merchants', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setSuccess('Merchant created successfully!');
      navigate('/marchants');
    } catch (error) {
      console.error('Failed to create merchant:', error);
      
      // Extract error messages from the API response
      const errorMessages = error.response?.data?.errors;
      const detailedError = errorMessages ? 
        Object.values(errorMessages).flat().join(', ') :
        'Failed to create merchant. Please try again.';

      setError(detailedError);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="create-marchant-page">
      <h2>Create Merchant</h2>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <form className="create-marchant-form">
        <div className="form-group">
          <label htmlFor="business_name">Business Name:</label>
          <input
            type="text"
            id="business_name"
            name="business_name"
            value={formData.business_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="business_type">Business Type:</label>
          <select
            id="business_type"
            name="business_type"
            value={formData.business_type}
            onChange={handleChange}
            required
          >
            <option value="corporation">Corporation</option>
            <option value="sole_proprietorship">Sole Proprietorship</option>
            <option value="partnership">Partnership</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="business_email">Business Email:</label>
          <input
            type="email"
            id="business_email"
            name="business_email"
            value={formData.business_email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="business_phone_code">Business Phone Code:</label>
          <input
            type="text"
            id="business_phone_code"
            name="business_phone_code"
            value={formData.business_phone_code}
            onChange={handleChange}
            required
            placeholder="+234"
          />
        </div>
        <div className="form-group">
          <label htmlFor="business_phone">Business Phone:</label>
          <input
            type="text"
            id="business_phone"
            name="business_phone"
            value={formData.business_phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="business_registration_number">Business Registration Number:</label>
          <input
            type="text"
            id="business_registration_number"
            name="business_registration_number"
            value={formData.business_registration_number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="acronym">Acronym:</label>
          <input
            type="text"
            id="acronym"
            name="acronym"
            value={formData.acronym}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="official_name">Official Name:</label>
          <input
            type="text"
            id="official_name"
            name="official_name"
            value={formData.official_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="website">Website:</label>
          <input
            type="url"
            id="website"
            name="website"
            value={formData.website}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="presence">Presence:</label>
          <select
            id="presence"
            name="presence"
            value={formData.presence}
            onChange={handleChange}
            required
          >
            <option value="physical">Physical</option>
            <option value="virtual">Virtual</option>
            <option value="hybrid">Hybrid</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="country_id">Country ID:</label>
          <input
            type="text"
            id="country_id"
            name="country_id"
            value={formData.country_id}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-buttons">
          <button type="button" onClick={handleSaveDraft} className="save-draft-button">
            Save Draft
          </button>
          <button
            type="button"
            onClick={handleCreateMarchant}
            className="create-marchant-button"
            disabled={loading} 
          >
            {loading ? (
              <HashLoader color="#fff" loading={loading} size={20} />
            ) : (
              'Create Merchant'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateMarchant;
