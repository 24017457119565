import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUsers, FaStore, FaHandHoldingUsd, FaRegLifeRing, FaBell, FaCog, FaUserCircle, FaSignOutAlt, FaFileAlt } from 'react-icons/fa';
import logo from './assets/logo.png';
import './styles/sidebar.css';

const Sidebar = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://api.visaro.ng/api/v2/admin', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setUsername(data.data.username);
        } else {
          throw new Error(data.message || 'Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error fetching user data
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-sticky">
        <div className="sidebar-logo">
          <img src={logo} alt="Logo" className="img-fluid" />
        </div>
        <div className="nav-item">
          <Link to="/" className="nav-link">
            <FaHome className="stat-icon mr-2" />
            Dashboard
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/users" className="nav-link">
            <FaUsers className="stat-icon mr-2" />
            Users
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/marchants" className="nav-link">
            <FaStore className="stat-icon mr-2" />
            Merchants
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/disburse-partners" className="nav-link">
            <FaHandHoldingUsd className="stat-icon mr-2" />
            Disburse Partners
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/report" className="nav-link">
            <FaFileAlt className="stat-icon mr-2" />
            Report
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/support" className="nav-link">
            <FaRegLifeRing className="stat-icon mr-2" />
            Support
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/notifications" className="nav-link">
            <FaBell className="stat-icon mr-2" />
            Notifications
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/settings" className="nav-link">
            <FaCog className="stat-icon mr-2 " />
            Settings
          </Link>
        </div>
      </div>
      <div className="sidebar-footer">
        <div className="admin-info">
          <FaUserCircle className="stat-icon mr-2" />
          <span className="username">{username}</span>
          <Link to="/logout" className="logout-link ml-2">
            <FaSignOutAlt />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
