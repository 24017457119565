import React, { useState, useEffect } from 'react';
import './styles/support.css';

const Support = () => {
  const [filter, setFilter] = useState('all');
  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState('');
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  const fetchSupportMessages = async () => {
    try {
      const token = localStorage.getItem('token'); 
      const response = await fetch('https://api.visaro.ng/api/v2/admin/support', {
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      });
      const result = await response.json();
      if (result.status) {
        setMessages(result.data.data || []); 
      } else {
        console.error('Failed to fetch messages:', result.message);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleReply = async (messageId) => {
    try {
      const token = localStorage.getItem('token'); 
      const response = await fetch('https://api.visaro.ng/api/v2/admin/support/respond', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message_id: messageId,
          response: replyMessage,
        }),
      });
      const result = await response.json();
      if (result.status) {
        alert('Reply sent successfully');
        setReplyMessage(''); 
      } else {
        console.error('Failed to send reply:', result.message);
      }
    } catch (error) {
      console.error('Error sending reply:', error);
    }
  };

  useEffect(() => {
    fetchSupportMessages(); // Fetch messages on component mount
  }, []);

  const filteredMessages = filter === 'all' ? messages : messages.filter(msg => msg.status === filter);

  return (
    <div className="support-container">
      <div className="filter-container">
        <select value={filter} onChange={(e) => setFilter(e.target.value)} className="filter-select">
          <option value="all">All</option>
          <option value="read">Read</option>
          <option value="unread">Unread</option>
        </select>
      </div>
      <div className="messages-container">
        {filteredMessages.map((msg) => (
          <div className="message-box" key={msg.id}>
            <div className="message-header">
              <img src={msg.avatar} alt="avatar" className="avatar" />
              <div className="user-info-new">
                <p className="name">{msg.firstName} {msg.lastName}</p>
                <p className="email">{msg.email}</p>
              </div>
              <p className="date">{msg.date}</p>
            </div>
            <div className="message-body">
              <p>{msg.message}</p>
            </div>
            <div className="reply-container">
              <input
                type="text"
                className="reply-input"
                placeholder="Type your reply..."
                value={selectedMessageId === msg.id ? replyMessage : ''}
                onChange={(e) => {
                  setSelectedMessageId(msg.id);
                  setReplyMessage(e.target.value);
                }}
              />
              <button
                className="reply-button"
                onClick={() => handleReply(msg.id)}
              >
                Send Reply
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Support;
