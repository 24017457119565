import React, { useState, useEffect } from 'react';
import './styles/disbursePartner.css';
import { FaSearch, FaFilter } from 'react-icons/fa';
import HashLoader from 'react-spinners/HashLoader';

const DisbursePartner = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [actionLoading, setActionLoading] = useState(false); 
  const [successMessage, setSuccessMessage] = useState(''); 

  const partnersPerPage = 2; // Adjust based on your needs

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.visaro.ng/api/v2/admin/partners', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const result = await response.json();

        if (response.ok) {
          setPartners(result.data);
          setFilteredPartners(result.data);
          setLoading(false);
        } else {
          setError(result.message || 'Failed to fetch partners');
          setLoading(false);
        }
      } catch (err) {
        setError('An error occurred while fetching data');
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  const handleDeactivate = async (id) => {
    setActionLoading(true); 
    try {
      const token = localStorage.getItem('token');
      const partner = partners.find((p) => p.id === id);
      const response = await fetch(`https://api.visaro.ng/api/v2/admin/partners/${id}/deactivate`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: partner.email,
          name: partner.name,
        }),
      });

      if (response.ok) {
        setPartners(partners.map((p) => (p.id === id ? { ...p, isActive: false } : p)));
        setSuccessMessage('Partner deactivated successfully!');
      } else {
        const result = await response.json();
        setError(result.message || 'Failed to deactivate partner');
      }
    } catch (err) {
      setError('An error occurred while deactivating partner');
    } finally {
      setActionLoading(false); // Stop loading
      setTimeout(() => setSuccessMessage(''), 3000); 
    }
  };

  const handleActivate = async (id) => {
    setActionLoading(true); 
    try {
      const token = localStorage.getItem('token');
      const partner = partners.find((p) => p.id === id);
      const response = await fetch(`https://api.visaro.ng/api/v2/admin/partners/${id}/activate`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: partner.email,
          name: partner.name,
        }),
      });

      if (response.ok) {
        setPartners(partners.map((p) => (p.id === id ? { ...p, isActive: true } : p)));
        setSuccessMessage('Partner activated successfully!');
      } else {
        const result = await response.json();
        setError(result.message || 'Failed to activate partner');
      }
    } catch (err) {
      setError('An error occurred while activating partner');
    } finally {
      setActionLoading(false); // Stop loading
      setTimeout(() => setSuccessMessage(''), 3000); 
    }
  };

  const indexOfLastPartner = currentPage * partnersPerPage;
  const indexOfFirstPartner = indexOfLastPartner - partnersPerPage;
  const currentPartners = filteredPartners.slice(indexOfFirstPartner, indexOfLastPartner);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterPartners(event.target.value);
  };

  const filterPartners = (searchTerm) => {
    const filtered = partners.filter((partner) =>
      partner.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPartners(filtered);
    setCurrentPage(1);
  };

  const handleFilterClick = () => {
    filterPartners(searchTerm);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="disburse-partner-page">
      <h2>Disbursement Partners</h2>

      {successMessage && <div className="success-message">{successMessage}</div>} 

      <div className="search-filter-section">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <FaSearch className="search-icon" />
        </div>
        <button className="filter-button" onClick={handleFilterClick}>
          <FaFilter className="filter-icon" />
          Filter
        </button>
      </div>

      <table className="partners-table">
        <thead>
          <tr>
            <th>Partner Name</th>
            <th>Partner Email</th>
            <th>Partner ID</th>
            <th>Total Requests</th>
            <th>Successful Requests</th>
            <th>Failed Requests</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPartners.map((partner) => (
            <tr key={partner.id}>
              <td>
                <div>{partner.name}</div>
              </td>
              <td>
                <div>{partner.email}</div>
              </td>
              <td>{partner.public_id}</td>
              <td>{partner.statistics.AllLoanCount}</td>
              <td>{partner.statistics.ApprovedLoanCount}</td>
              <td>{partner.statistics.DeclinedLoanCount}</td>
              <td>
                {partner.isActive ? (
                  <button
                    className="deactivate-button"
                    onClick={() => handleDeactivate(partner.id)}
                    disabled={actionLoading} 
                  >
                    {actionLoading ? <HashLoader size={15} color="#fff" /> : 'Deactivate'}
                  </button>
                ) : (
                  <button
                    className="activate-button"
                    onClick={() => handleActivate(partner.id)}
                    disabled={actionLoading} 
                  >
                    {actionLoading ? <HashLoader size={15} color="#fff" /> : 'Activate'}
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredPartners.length / partnersPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DisbursePartner;
