import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLoader } from 'react-spinners';
import './styles/addAdmin.css';

const AddAdmin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('admin');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCreateAdmin = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const requestData = {
      email,
      password,
      username,
      role,
    };

    try {
      const response = await fetch('https://api.visaro.ng/api/v2/admin/create-admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        navigate('/settings'); // Redirect to settings page on success
      } else {
        const errorData = await response.json();
        console.error('Failed to create admin:', errorData.message || 'Unknown error');
        // Handle error scenario, e.g., display an error message
      }
    } catch (error) {
      console.error('Error creating admin:', error);
      // Handle network or other errors
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-admin-container">
      <h2>Create Admin User</h2>
      <p>Fill out the details to create an admin user.</p>
      <form className="add-admin-form">
        <div className="form-field">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="role">Role</label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="superadmin">Super Admin</option>
            {/* Add other role options as needed */}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-buttons">
          <button
            type="button"
            className="create-admin-btn"
            onClick={handleCreateAdmin}
            disabled={loading}
          >
            {loading ? <HashLoader size={20} color="#fff" /> : 'Create Admin'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAdmin;
